import styles from "../about/about.module.css";
import randomImg from "../../Assets/Images/Final_img/10.jpg";
import teamImg from "../../Assets/Images/Final_img/10.jpg";
import aboutCoverImg from "../../Assets/Images/Final_img/10.jpg";
import aboutImg from "../../Assets/Images/Services/img4.webp";
import videoOfLeft from "../../Assets/videoplayback (1).mp4";

const Home_clean = () => {
  return (
    <>
      <div className={styles.wrapper}>
        {/* <div
          className={styles.heading}
          style={{ backgroundImage: `url(${aboutCoverImg})` }}
        >
          {" "}
          Delivery & Installation{" "}
        </div> */}

        {/* <div className={styles.ourStoryWrapper}>
          <span className={styles.ourStoryHead}>
          
          </span>
          <div className={styles.ourStoryText}>
            <span className={styles.ourStoryHead}>
              Importance of home cleaning :-
            </span>

            <span className={styles.ourStoryDesc}>
            
            </span>
          </div>

        </div> */}

        <div className={styles.about_Service}>
          <div className={styles.about_text_content}>
            {/* <span className={styles.aboutHead}>
              {" "}
              Capital Climb - 2X: Secure Land Investments with Guaranteed Growth
            </span> */}
            <h4>On-site Cafeteria</h4>
            <span className={styles.aboutDesc}>
             Full-service management of your office cafeteria with tailored, fresh menu options.

              <br />
              <br />
            </span>
          </div>

          {/* <img src={randomImg} className={styles.aboutImg} alt="" /> */}
          <div className={styles.video}>
            {/* <video width="100%" height="100%" controls>
              <source
                src={videoOfLeft}
                type="video/mp4"
                autoplay
                muted
                controls
              />
              Your browser does not support the video tag.
            </video> */}
            <img src={aboutImg} alt="" />
          </div>
        </div>

        <div className={styles.ourStoryWrapper}>
          <div className={styles.ourStoryText}>
            {/* <span className={styles.ourStoryHead}> With Our Teams </span> */}

            {/* <span className={styles.ourStoryDesc}>
              With Capital Climb - 2X, retail investors have the opportunity to
              invest securely. In this model, investments are used exclusively
              for acquiring premium land parcels, which are carefully selected
              for high growth potential. The team ensures that the land has
              clear titles and legal approvals, with bank support in place for
              each project. <br />
              Investors enjoy an unconditional 2x return on investment within 5
              years, secured against tangible land assets. The proven model of
              successful land trading and joint development minimizes risk,
              while investor participation helps expand the network across India
              and beyond, unlocking wider sales opportunities. <br />
              This model is suitable for investors looking to diversify their
              portfolios or invest in a stable, high-growth real estate market,
              providing a secure path to double investments in just 5 years
              without any operational engagement. It capitalizes on Goa’s
              booming real estate market with confidence, ensuring all
              investments are safe and yield guaranteed returns.
              <br />
              Business Opportunities on Floor Land ,Villa & Apartments
            </span> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Home_clean;
