import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import {
  Home,
  About,
  ContactUs,
  Testimonials,
  Bbq,
  Office,
  Spring,
  Home_clean,
  Carpet,
  Gardening,
  EndOfLease,
} from "./Pages";
import { Footer, Navbar } from "./Components";
import Servicess from "./Pages/Services_page/Servicess";
import Gallery from "./Pages/Gallery/Gallery";
import Categories from "./Pages/Categories/Categories.jsx";
import SingleProductDetails from "./Pages/SingleProductDetails/SingleProductDetails.jsx";
import SingleImgDetails from "./Components/SingleImgDetails/SingleImgDetails.jsx";
import "./App.css";
import whatsapp from "./Assets/Images/icons8-whatsapp-48.png";

function App() {
  return (
    <div className="Main-con">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/services" element={<Servicess />} />
          <Route path="/goaland" element={<Bbq />} />
          <Route path="/Rentals" element={<Office />} />
          <Route path="/preset" element={<EndOfLease />} />
          <Route path="/Spring-services" element={<Spring />} />
          <Route path="/2xcapital" element={<Home_clean />} />
          <Route path="/PaisaGoa" element={<Gardening />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/jointventure" element={<Carpet />} />
          <Route path="/Categories" element={<Categories />} />
          <Route
            path="/singleproductdetails"
            element={<SingleProductDetails />}
          />
          {/* <Route path="/SingleImgDetails/:sku" element={<SingleImgDetails />} /> */}
          <Route path="/SingleImgDetails" element={<SingleImgDetails />} />
        </Routes>
        {/* <Upper_Footer /> */}

        <div className="whatsapp">
          <a
            href="https://wa.me/+918689878791?text=Hello!%20I%20would%20like%20to%20know%20more%20about%20your%20services."
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whatsapp} alt="WhatsApp" />
          </a>
        </div>
        {/* <Footer /> */}
      </Router>
    </div>
  );
}

export default App;
