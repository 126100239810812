import styles from "../../Components/Services/Services.module.css";
import FAQ from "../../Components/FAQ/FAQ";

import { Services } from "../../Components";
import img1 from "../../Assets/Images/Services/img1.webp";
import img2 from "../../Assets/Images/Services/img2.webp";
import img3 from "../../Assets/Images/Services/img3.webp";
import img4 from "../../Assets/Images/Services/img4.webp";

const data = [
  {
    title: "What material do you use in making furniture?",
    description:
      "We deal in all sorts of engineered wood namely Ply, MDF, HDHMR and PLPB. Besides, we work in both sheet metal and MS pipes too. At certain places we use SS, Glass and Fabric too. Sofa structures are made both in Metal and Wood.",
  },
  {
    title: "How is factory made furniture better than carpentry work?",
    description:
      "Furniture at our factory is made with advance technology driven German & Japanese machinery which ensures precision and finesse with all the edges being subtle and smooth,  We have Hot Press Machine for pasting laminate which works with high pressure and temperature,  Hassel free process for the customer as factory made furniture involves no mess at the site, The ready to install material is delivered in knock down state and the only job done at site is of Installation of parts.",
  },
  {
    title: "Where in India do you supply your furniture?",
    description:
      "We have dual manufacturing facilities, 1 at Bangalore and other at Indore and we do pan India delivery.",
  },
  {
    title: "Do you customize designs as per client’s need?",
    description:
      "Yes, absolutely! Infact bespoke furniture is our USP and we can customize designs as per our client’s need.",
  },
  {
    title: "What is the warranty period offered on your products?",
    description:
      "A Warranty period of 1 year from the date of delivery on manufacturing defects is offered by us.",
  },
  {
    title: "What is the turnaround time of an order?",
    description:
      "The exact turnaround time depends on the designs, quantum of work and the availability of material in stock however, in general we take",
  },
];

function ServiceCard({ serviceName, imgURL, Icon }) {
  return (
    <div
      className={styles.cardWrapper}
      style={{ backgroundImage: `url(${imgURL})` }}
    >
      <Icon className={styles.serviceLogo} />
      <span className={styles.serviceName}> {serviceName} </span>
    </div>
  );
}
function Servicess() {
  return (
    <>
      <div className={styles.contact_header}> Our Services </div>

      <div className={styles.servicecon}>
        <div className={styles.service1}>
          <div className={styles.leftImg}>
            <img src={img1} alt="" />
          </div>
          <div className={styles.rightCon}>
            <h4> Corporate Catering</h4>
            <p>
              From everyday office meals to VIP client lunches, we’ve got you
              covered.
            </p>
          </div>
        </div>

        <div className={styles.service1}>
          <div className={styles.rightCon}>
            <h4>Event Catering</h4>
            <p>
              Hosting a seminar or celebration? We’ll handle the food – you
              handle the success.
            </p>
          </div>

          <div className={styles.leftImg}>
            <img src={img3} alt="" />
          </div>
        </div>

        <div className={styles.service1}>
          <div className={styles.leftImg}>
            <img src={img4} alt="" />
          </div>
          <div className={styles.rightCon}>
            <h4>On-site Cafeteria</h4>
            <p>
              Full-service management of your office cafeteria with tailored,
              fresh menu options.
            </p>
          </div>
        </div>

        <div className={styles.service1}>
          <div className={styles.rightCon}>
            <h4> Custom Menus</h4>
            <p>
            Regional flavors? International dishes? Special diets? We customize it all.

            </p>
          </div>

          <div className={styles.leftImg}>
            <img src={img2} alt="" />
          </div>
        </div>
      </div>

      <div className={styles.wrapper}>
        <Services />
      </div>

      <div className={styles.faqContainer}>
        <FAQ data={data} />
      </div>
    </>
  );
}

export default Servicess;
