import styles from "../about/about.module.css";
import aboutCoverImg from "../../Assets/Images/Services/img1.webp";
import videoOfLeft from "../../Assets/videoplayback (1).mp4";

const EndOfLease = () => {
  return (
    <>
      <div className={styles.wrapper}>
        {/* <div
          className={styles.heading}
          style={{  }}
        >
          {" "}
          Design Consultation{" "}
        </div> */}

        <div className={styles.about_Service}>
          <div className={styles.about_text_content}>
            <h4>Corporate Catering</h4>
            <p className={styles.aboutDesc}>
              From everyday office meals to VIP client lunches, we’ve got you
              covered.
            </p>
          </div>

          <div className={styles.video}>
            {/* <video width="100%" height="100%" controls>
              <source
                src={videoOfLeft}
                type="video/mp4"
                autoplay
                muted
                controls
              />
              Your browser does not support the video tag.
            </video> */}
            <img src={aboutCoverImg} alt="" />
          </div>
        </div>

        {/* <div className={styles.ourStoryWrapper}>
          <div className={styles.ourStoryText}>
            <h2 style={{ textAlign: "center", margin: 0, marginTop: "10px" }}>
              BOND CLEANING INCLUSIONS ( UNFURNISHED PROPERTY )
            </h2>

            <div>
              <h3 className={styles.subHeading}>General </h3>
              <ul>
                <li>
                  {" "}
                  Remove cobwebs, insect marks and small nests where we are
                  accessible{" "}
                </li>
                <li>
                  {" "}
                  Clean fans, Air Conditioners (a.c) and filters also wipe
                  around the air conditioners.
                </li>
                <li>
                  {" "}
                  Clean light fittings and switch boards (where they can be
                  safely removed and accessible)
                </li>
                <li> Spot clean walls, light switches and power points</li>
                <li> Clean skirting, and doors and frames.</li>
                <li>
                  {" "}
                  Clean drawers and cupboards including inside, doors and
                  cupboard tops and shelving
                </li>
                <li>
                  {" "}
                  Windows clean both sides (exclusions apply at heights and
                  accessibility)
                </li>
                <li> Vacuum sliding door tracks and clean tracks</li>
                <li>
                  {" "}
                  Clean standard blinds, cords and rails (spot clean and dust).
                </li>
                <li> Vacuum all the floors and carpets.</li>
                <li> Mopping floors.</li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default EndOfLease;
