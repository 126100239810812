import React from "react";
import "./Carousel.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// images
import man1 from "../../Assets/testimonial/OIP (1).jpeg";
import man2 from "../../Assets/testimonial/OIP (2).jpeg";
import man3 from "../../Assets/testimonial/OIP.jpeg";

export const Carousel = () => {
  const PreviousBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div>
        <svg
          className={className}
          onClick={onClick}
          style={{ transform: "rotate(180deg)" }}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="24"
          viewBox="0 0 15 24"
          fill="none"
        >
          <path
            d="M2 2L12 12L2 22"
            stroke="#52E6C2"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </div>
    );
  };

  const NextBtn = (props) => {
    const { className, onClick } = props;
    return (
      <div>
        <svg
          className={className}
          onClick={onClick}
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="24"
          viewBox="0 0 15 24"
          fill="none"
        >
          <path
            d="M2 2L12 12L2 22"
            stroke="#52E6C2"
            stroke-width="3"
            stroke-linecap="round"
          />
        </svg>
      </div>
    );
  };

  var settings = {
    prevArrow: <PreviousBtn />,
    nextArrow: <NextBtn />,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 680,
        settings: {
          // prevArrow: null,
          // nextArrow: null,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      client_name: "Rajiv S., Corporate Client",
      client_message:
        "Simply the best! Bharti Food & Hospitality turned our corporate event into a culinary success.",
      image: man1,
    },
    {
      client_name: "Sneha T., Office Manager",
      client_message: `Our office meal service has never been better. Fresh, tasty, and always on time!`,
      image: man2,
    },
    // {
    //   client_name: "Rajiv S., Corporate Client",
    //   client_message:
    //     "Simply the best! Bharti Food & Hospitality turned our corporate event into a culinary success.",
    //   image: man1,
    // },

  ];

  return (
    <div className="slider_carousel">
      <Slider {...settings}>
        {testimonials?.map((el, i) => {
          return (
            <div className="slider_item">
              <figure className="main-container">
                <div className="author">
                  <h5>{el?.client_name}</h5>

                  <figcaption>
                    <blockquote>{el?.client_message}</blockquote>
                    <div className="arrow"></div>
                  </figcaption>
                </div>

                <img src={el?.image} alt="sq-sample10" />
              </figure>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};
