import React from "react";
import { useState } from "react";
import "./Categories.scss";
import CategoriesData from "./CategoriesData";
import AllCardCategories from "../../Components/AllCardCategories/AllCardCategories";
import { useLocation } from "react-router-dom";

export default function Categories() {
  const location = useLocation();
  const { type } = location.state || {};
  // office data
  const executiveDesk = CategoriesData.officeFurniture.exicutiveDesk;
  const standardTable = CategoriesData.officeFurniture.standardTable;
  const conferenceAndMeetingTable =
    CategoriesData.officeFurniture.conferenceAndMeetingTable;
  const workstationOpenDesk =
    CategoriesData.officeFurniture.workstationOpenDesk;
  const workstationCubicless =
    CategoriesData.officeFurniture.workstationCubicless;
  const raceptionCounter = CategoriesData.officeFurniture.raceptionCounter;
  const storageCabinets = CategoriesData.officeFurniture.storageCabinets;

  // metal data
  const metalalmirah = CategoriesData.metalStorage.metalalmirah;
  const lokers = CategoriesData.metalStorage.lokers;
  const filingcabinets = CategoriesData.metalStorage.filingcabinets;
  const bookshelves = CategoriesData.metalStorage.bookshelves;
  const slottedAngleRacks = CategoriesData.metalStorage.slottedAngleRacks;

  // office chair and sofa
  const officeChairSofas = CategoriesData.officeChairSofas;

  // compactor
  const compactors = CategoriesData.compactors;

  // Institutional Furniture
  const smartDeskAndDualDesk =
    CategoriesData.institutionalFurniture.smartDesksDualDesks;
  const cumputerLabAndLibraryDesk =
    CategoriesData.institutionalFurniture.computerLabAndLibraryDesk;
  const conteenAndCafeteriaFurniture =
    CategoriesData.institutionalFurniture.canteenAndCafeteriaFurniture;
  const libraryRacks = CategoriesData.institutionalFurniture.labraryRacks;
  const podium = CategoriesData.institutionalFurniture.podium;
  const computerTable = CategoriesData.institutionalFurniture.computerTable;

  // hostel and hotel furniture
  const HotelAndHostelFurniture = CategoriesData.HotelAndHostelFurniture;

  // Laboratoryfurniture
  const scienceLab = CategoriesData.Laboratoryfurniture.ScienceLab;

  // homeFurniture
  const homeFurniture = CategoriesData.homeFurniture;

  const [selectedCategory, setSelectedCategory] = useState("ExecutiveDesk");

  // Handling the category change
  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <div>
      {/* office furniture */}
      {type === "officeFurniture" && (
        <div>
          <div className="dropdowndiv">
            <h4 className="">Office Furniture </h4>
            <select
              onChange={handleCategoryChange}
              className="form-select mb-3 w-25 "
            >
              <option value="ExecutiveDesk">ExecutiveDesk</option>
              <option value="standardTable">Standard Table</option>
              <option value="conferenceAndMeetingTable">
                Conference and Meeting Table
              </option>
              <option value="workstationOpendesking">
                work station (Open desking)
              </option>
              <option value="workstationCubicless">
                Workstation Cubicless
              </option>
              <option value="receptionCounter">Reception Counter</option>
              <option value="storageCabinets">Storage Cabinets</option>
            </select>
          </div>

          {selectedCategory === "ExecutiveDesk" && (
            <div>
              <h5 className="text-center">Executive Desk</h5>
              <AllCardCategories items={executiveDesk} />
            </div>
          )}

          {selectedCategory === "standardTable" && (
            <div>
              <h5 className="text-center">Standard Table</h5>
              <AllCardCategories items={standardTable} />
            </div>
          )}

          {selectedCategory === "conferenceAndMeetingTable" && (
            <div>
              <h5 className="text-center">Conference And Meeting Table</h5>
              <AllCardCategories items={conferenceAndMeetingTable} />
            </div>
          )}

          {selectedCategory === "workstationOpendesking" && (
            <div>
              <h5 className="text-center">work station (Opendesking)</h5>
              <AllCardCategories items={workstationOpenDesk} />
            </div>
          )}

          {selectedCategory === "workstationCubicless" && (
            <div>
              <h5 className="text-center">Workstation Cubicless</h5>
              <AllCardCategories items={workstationCubicless} />
            </div>
          )}

          {selectedCategory === "receptionCounter" && (
            <div>
              <h5 className="text-center">Reception Counter</h5>
              <AllCardCategories items={raceptionCounter} />
            </div>
          )}

          {selectedCategory === "storageCabinets" && (
            <div>
              <h5 className="text-center">Storage Cabinets</h5>
              <AllCardCategories items={storageCabinets} />
            </div>
          )}
        </div>
      )}

      {/* metal storage */}
      {type === "metalstorage" && (
        <div>
          <div className="dropdowndiv">
            <h4 className="text-center mb-2">metal storage</h4>
            <select
              onChange={handleCategoryChange}
              className="form-select mb-3 w-25"
            >
              <option value="ExecutiveDesk">Metal Almirah</option>
              <option value="standardTable">Lockers</option>
              <option value="conferenceAndMeetingTable">Filing Cabinets</option>
              <option value="workstationCubicless">Book Shelves</option>
              <option value="receptionCounter">Slotted Angle Racks</option>
            </select>
          </div>

          {selectedCategory === "ExecutiveDesk" && (
            <div>
              <h5 className="text-center">Metal almirah</h5>
              <AllCardCategories items={metalalmirah} />
            </div>
          )}

          {selectedCategory === "standardTable" && (
            <div>
              <h5 className="text-center">Lockers</h5>
              <AllCardCategories items={lokers} />
            </div>
          )}

          {selectedCategory === "conferenceAndMeetingTable" && (
            <div>
              <h5 className="text-center">Filling Cabinets</h5>
              {/* <AllCardCategories items={filingcabinets} /> */}
              <p className="text-center text-danger mt-5 mb-5 ">
                No products here
              </p>
            </div>
          )}

          {selectedCategory === "workstationCubicless" && (
            <div>
              <h5 className="text-center">book Shelves</h5>
              <AllCardCategories items={bookshelves} />
            </div>
          )}

          {selectedCategory === "receptionCounter" && (
            <div>
              <h5 className="text-center">Slotted Angle Racks</h5>
              {/* <AllCardCategories items={slottedAngleRacks} /> */}
              <p className="text-center text-danger mt-5 mb-5 ">
                No products here
              </p>
            </div>
          )}
        </div>
      )}

      {/* office chair sofa */}
      {type === "officeChair&sofa" && (
        <div>
          <h4 className="text-center">office Chair & sofa</h4>
          <AllCardCategories items={officeChairSofas} />
        </div>
      )}

      {/* compactors */}
      {type === "compactors" && (
        <div>
          <h4 className="text-center">compactors</h4>
          <AllCardCategories items={compactors} />
        </div>
      )}

      {/* institutional furniture */}
      {type === "institutionalfurniture" && (
        <div>
          <div className="dropdowndiv">
            <h4 className="text-center mb-2">institutional furniture</h4>
            <select
              onChange={handleCategoryChange}
              className="form-select mb-3 w-25"
            >
              <option value="ExecutiveDesk">Smart Desk & Dual Desks</option>
              <option value="standardTable">
                Computer Lab And Library Desks{" "}
              </option>
              <option value="conferenceAndMeetingTable">
                Canteen And Cafeteria Furniture
              </option>
              <option value="workstationCubicless">Library Racks</option>
              <option value="Computertbale">Computer Table</option>
              <option value="receptionCounter">Podium</option>
            </select>
          </div>

          {selectedCategory === "ExecutiveDesk" && (
            <div>
              <h5 className="text-center">Smart Desks & Dual Desks</h5>
              <AllCardCategories items={smartDeskAndDualDesk} />
            </div>
          )}

          {selectedCategory === "standardTable" && (
            <div>
              <h5 className="text-center">Computer Lab And Library Desks</h5>
              <AllCardCategories items={cumputerLabAndLibraryDesk} />
            </div>
          )}

          {selectedCategory === "conferenceAndMeetingTable" && (
            <div>
              <h5>Canteen And Cafeteria Furniture</h5>
              <AllCardCategories items={conteenAndCafeteriaFurniture} />
            </div>
          )}

          {selectedCategory === "workstationCubicless" && (
            <div>
              <h5>Library Racks</h5>
              <AllCardCategories items={libraryRacks} />
            </div>
          )}
          {selectedCategory === "Computertbale" && (
            <div>
              <h5>Computer Tables</h5>
              <AllCardCategories items={computerTable} />
            </div>
          )}

          {selectedCategory === "receptionCounter" && (
            <div>
              <h5>Podium</h5>
              <AllCardCategories items={podium} />
            </div>
          )}
        </div>
      )}

      {/* hotel hostel */}
      {type === "hotel&hostel" && (
        <div>
          <h4 className="text-center">hotel & hostel Furniture</h4>
          <AllCardCategories items={HotelAndHostelFurniture} />
        </div>
      )}

      {/* laboratory furniture */}
      {type === "laboratorufurniture" && (
        <div>
          <div className="dropdowndiv">
            <h4 className="text-center mt-4 mb-2">laboratoru furniture</h4>
            <select
              onChange={handleCategoryChange}
              className="form-select mb-3 w-25"
            >
              <option value="ExecutiveDesk">Science Labs </option>
              <option value="standardTable">Commercial Labs</option>
            </select>
          </div>

          {selectedCategory === "ExecutiveDesk" && (
            <div>
              <h5 className="text-center">Seience Labs</h5>
              <AllCardCategories items={scienceLab} />
            </div>
          )}

          {selectedCategory === "standardTable" && (
            <div className="">
              <h5 className="text-center">Commercial Labs</h5>

              <p className="text-center text-danger mt-5 mb-5 ">
                No products here
              </p>
            </div>
          )}
        </div>
      )}

      {/* home furniture */}
      {type === "homefurniture" && (
        <div>
          <h4 className="text-center mt-5 mb-2">home furniture</h4>
          <AllCardCategories items={homeFurniture} />
        </div>
      )}
    </div>
  );
}
