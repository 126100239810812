import { useEffect, useState } from "react";
import styles from "./navbar.module.css";
import Logo from "../../Assets/Bharti_Logo__Orange_Final.png";
import { useNavigate, NavLink } from "react-router-dom";
import { IoCall } from "react-icons/io5";
import { FaTimes } from "react-icons/fa";
import emailjs from "@emailjs/browser";

import { ToastContainer, toast } from "react-toastify";

const Hamburger = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="24"
    viewBox="0 0 52 24"
  >
    <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="42"
        height="4"
        rx="2"
        transform="translate(304 47)"
        fill="#574c4c"
      />
      <rect
        id="Rectangle_5"
        data-name="Rectangle 5"
        width="42"
        height="4"
        rx="2"
        transform="translate(304 67)"
        fill="#574c4c"
      />
      <rect
        id="Rectangle_4"
        data-name="Rectangle 4"
        width="52"
        height="4"
        rx="2"
        transform="translate(294 57)"
        fill="#574c4c"
      />
    </g>
  </svg>
);

function Navbar() {
  // ..
  // ..//
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleModal = () => {
    console.log("Click modal");
    setShow(true);
  };

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = currentScrollPos != 0;
      setIsScrolledDown(isScrollingDown);
      prevScrollPos = currentScrollPos;
      console.log(isScrolledDown);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // dropdown
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
    console.log("done");
  };

  return (
    <nav className={styles.navbar}>
      <div className={styles.container}>
        <div className={styles.logoContainer}>
          <img
            src={Logo}
            alt="logo"
            className={styles.logo}
            onClick={() => navigate("/")}
          />
        </div>

        {/* <div className={styles.menu_icon} onClick={handleShowNavbar}>
          <Hamburger />
        </div> */}

        <div className={`nav-header ${styles.navheader}`}>
          <ul className="d-flex gap-3 fw-bold text-black list-unstyled">
            {/* <li>
              <NavLink
                to="/"
                className={({ isActive }) =>
                  isActive ? `${styles.activeLink}` : "text-black"
                }
              >
                Home
              </NavLink>
            </li> */}

            {/* <div className="dropdown">
              <button
                className=" dropdown-toggle fw-bold bg-transparent"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
               
                Categories
              </button>
              <ul className="dropdown-menu">
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "officeFurniture" },
                      })
                    }
                  >
                    Office Furniture
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "metalstorage" },
                      })
                    }
                  >
                    Metal Storage
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "officeChair&sofa" },
                      })
                    }
                  >
                    Office Chairs & Sofas
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "compactors" },
                      })
                    }
                  >
                    Compactors
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "institutionalfurniture" },
                      })
                    }
                  >
                    Institutional Furniture
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "hotel&hostel" },
                      })
                    }
                  >
                    Hotel & Hostel Furniture
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "laboratorufurniture" },
                      })
                    }
                  >
                    Laboratory Furniture
                  </button>
                </li>
                <li>
                  <button
                    className="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "homefurniture" },
                      })
                    }
                  >
                    Home Furniture
                  </button>
                </li>
              </ul>
            </div> */}

            {/* Apply NavLink for each menu item */}
            {/* <li>
              <NavLink
                to="/services"
                className={({ isActive }) =>
                  isActive ? `${styles.activeLink}` : "text-black"
                }
              >
                Services
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/gallery"
                className={({ isActive }) =>
                  isActive ? `${styles.activeLink}` : "text-black"
                }
              >
                Gallery
              </NavLink>
            </li> */}

            {/* About Link: Change color and border when active */}
            {/* <li>
              <NavLink
                to="/about"
                className={({ isActive }) =>
                  isActive ? `${styles.activeLink}` : "text-black"
                }
              >
                About
              </NavLink>
            </li>

            <li>
              <NavLink
                to="/contact"
                className={({ isActive }) =>
                  isActive ? `${styles.activeLink}` : "text-black"
                }
              >
                Contact Us
              </NavLink>
            </li> */}
          </ul>
        </div>

        {/* for mobile */}
        {/* <div
          className={`${styles.nav_elements} ${showNavbar && styles.active}`}
        >
          <div
            className={styles.closeIcon}
            onClick={() => setShowNavbar(!showNavbar)}
          >
            <FaTimes />
          </div>

          <div className={styles.btnContainer}>
            <ul className="list-unstyled fw-bold">
              <li className="mt-2" onClick={() => navigate("/")}>
                Home{" "}
              </li>

              <li className="mt-2" onClick={() => navigate("/services")}>
                Services
              </li>
              <li className="mt-2" onClick={() => navigate("/gallery")}>
                Gallery
              </li>
              <li className="mt-2" onClick={() => navigate("/about")}>
                About
              </li>
              <li className="mt-2" onClick={() => navigate("/contact")}>
                Contact Us
              </li>
            </ul>
          </div>
        </div> */}

        {showNavbar && (
          <div
            className={styles.sidebarOverlay}
            onClick={() => setShowNavbar(!showNavbar)}
          />
        )}
      </div>

      <ToastContainer />
    </nav>
  );
}

export default Navbar;

{
  /* <div className={`nav-header ${styles.navheader}`}>
          <ul className="d-flex gap-3 fw-bold list-unstyled">
            <li onClick={() => navigate("/")}>Home </li>

            <div class="dropdown">
              <button
                class=" dropdown-toggle fw-bold bg-transparent"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Categories
              </button>
              <ul class="dropdown-menu">
                <li>
                  <button
                    class="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "officeFurniture" },
                      })
                    }
                  >
                    Office Furniture
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "metalstorage" },
                      })
                    }
                  >
                    Metal Storage
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "officeChair&sofa" },
                      })
                    }
                  >
                    Office Chairs & Sofas
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "compactors" },
                      })
                    }
                  >
                    Compactors
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "institutionalfurniture" },
                      })
                    }
                  >
                    Institutional Furniture
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "hotel&hostel" },
                      })
                    }
                  >
                    Hotel & Hostel Furniture
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "laboratorufurniture" },
                      })
                    }
                  >
                    Laboratory Furniture
                  </button>
                </li>
                <li>
                  <button
                    class="dropdown-item"
                    type="button"
                    onClick={() =>
                      navigate("/Categories", {
                        state: { type: "homefurniture" },
                      })
                    }
                  >
                    Home Furniture
                  </button>
                </li>
              </ul>
            </div>
            <li onClick={() => navigate("/services")}>Services</li>
            <li onClick={() => navigate("/gallery")}>Gallery</li>
            <li onClick={() => navigate("/about")}>About</li>
            <li onClick={() => navigate("/contact")}>Contact Us</li>
          </ul>
        </div> */
}
