import founder1 from "../Assets/testimonial/OIP (2).jpeg";
import founder2 from "../Assets/testimonial/OIP.jpeg";
import founder3 from "../Assets/bharti img/depositphotos_30861025-stock-photo-chef-cooking-in-his-kitchen.jpg";
import teams from "../Assets/bharti img/kichenmem.webp";
import food from "../Assets/bharti img/food.jpg";
import solution from "../Assets/bharti img/CorporateCatering.jpg";

import img2 from "../Assets/bharti img/EventCatering.jpg";
import img3 from "../Assets/bharti img/On-siteCafeteria.jpg";
import img4 from "../Assets/bharti img/Custom Menus img.webp";

export const founderList = [
  {
    name: "Corporate Catering",
    designation: "Taste",
    profileImage: solution,
    about:
      " From everyday office meals to VIP client lunches, we’ve got you covered.",
  },
  {
    name: "Event Catering",
    designation: "Taste",
    profileImage: img2,
    about:
      "Hosting a seminar or celebration? We’ll handle the food – you handle the success",
  },
  {
    name: "On-site Cafeteria:",
    profileImage: img3,
    about:
      " Full-service management of your office cafeteria with tailored, fresh menu options.",
  },
  {
    name: "Custom Menus",
    profileImage: img4,
    about:
      "Regional flavors? International dishes? Special diets? We customize it all",
  },
];

export const perksList = [
  {
    perk: "Guaranteed customer satisfaction every time.",
  },
  {
    perk: "Cleaning services that give you great value for your money.",
  },
  {
    perk: "A team of talented and skilled cleaners ready to tackle any job.",
  },
  {
    perk: "Eco-friendly practices that reduce our environmental impact.",
  },
];
