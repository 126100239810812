import styles from "./ContactUs.module.css";
import { FaInstagram, FaFacebook } from "react-icons/fa";
import emailjs from "@emailjs/browser";
import { useState } from "react";
import { useEffect } from "react";
import { FaYoutube } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";

function ContactUs() {
  const options = {
    publicKey: process.env.REACT_PUBLIC_KEY,
    // Do not allow headless browsers
    blockHeadless: true,
    limitRate: {
      // Set the limit rate for the application
      id: "best1_cleaners",
      // Allow 1 request per 10s
      throttle: 1000,
    },
  };

  const [formData, setFormData] = useState({
    email: "",
    message: "",
    phoneNumber: "",
    fullName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Replace these with your actual email service, template ID, and user ID
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    console.log("serviceId: ", serviceId);
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    console.log("templateId: ", templateId);

    try {
      await emailjs.send(serviceId, templateId, formData, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      });

      // Clear form data after successful submission
      setFormData({
        email: "",
        phoneNumber: "",
        message: "",
        fullName: "",
      });

      alert("Email sent successfully!");
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  useEffect(() => {
    emailjs.init(options);
  }, []);

  return (
    <div className={styles.contact_us}>
      <h2 className={`text-center ${styles.heading}`}>Contact Us</h2>

      <div className={styles.contactFormAndAddress}>
        <div className={styles.contact_locations}>
          <h4 className={styles.subheading}>Get In Touch</h4>

          <div className={styles.contactContainer}>
            {/* <div>
              <h6>Mobile Number</h6>
              <p>+91 9302055555</p>
            </div> */}

            <div>
              <h5>Email</h5>

              <p>info@bhartifoodhospitality.com </p>
            </div>
          </div>

          <div className={styles.socialLinks}>
            <h5>Social Media </h5>

            <div style={{ marginTop: "7px" }}>
              <p>
                {" "}
                <a
                  className="text-danger"
                  href="https://www.instagram.com/bhartifoodsandhospitality/"
                  target="_black"
                >
                  <FaInstagram size={30} />
                </a>{" "}
              </p>
              <p>
                <a
                  href="https://www.facebook.com/profile.php?id=61566439217545"
                  target="_black"
                >
                  <FaFacebook size={30} />
                </a>{" "}
              </p>

              {/* <p>
                {" "}
                <a
                  className="text-danger"
                  href="#"
                  target="_black"
                >
                  <FaYoutube size={30} />
                </a>{" "}
              </p> */}
              <p>
                {" "}
                {/* <a
                  className="text-primary"
                  href="#"
                  target="_black"
                >
                  <FaLinkedin size={30} />
                </a>{" "} */}
              </p>
            </div>
          </div>

          <div className={styles.locationContainer}>
            <h5>Location</h5>
            <p className="mt-3">
              {" "}
              <span className={styles.span}>INDORE</span> {""} 364, pu4. Pin
              Code 452010 Madhya Pradesh
            </p>
          </div>
        </div>

        <form className={styles.contact_form} onSubmit={handleSubmit}>
          <h4 className={`text-center ${styles.subheading}`}>Send A Message</h4>

          <div className={styles.formField}>
            <div className={styles.label}>Full Name</div>
            <input
              className={styles.formInput}
              type="text"
              id="fullName"
              required
              name="fullName"
              onChange={handleInputChange}
            />
          </div>

          <div className={styles.formField}>
            <div className={styles.label}>Email</div>
            <input
              className={styles.formInput}
              type="email"
              onChange={handleInputChange}
              id="email"
              required
              name="email"
            />
          </div>

          <div className={styles.formField}>
            <div className={styles.label}>Phone</div>
            <input
              className={styles.formInput}
              type="tel"
              required
              id="phone"
              onChange={handleInputChange}
              name="phoneNumber"
            />
          </div>

          <div className={styles.formField}>
            <div className={styles.label}> Message </div>
            <textarea
              className={styles.formInput}
              id="jobDescription"
              name="jobDescription"
              onChange={handleInputChange}
              rows="4"
            />
          </div>

          <button className={styles.submitBtn} type="submit">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
}

export default ContactUs;
