import styles from "./home.module.css";
import { Services } from "../../Components";
import { useNavigate } from "react-router-dom";
import livingRoom from "../../Assets/bharti img/service/catering (2).png";
import "./modal.css";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import Testimonials from "../Testimonial/Testimonials";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import FAQ from "../../Components/FAQ/FAQ";
import HomeCrousel from "../../Components/HomeCrousel/HomeCrousel";
import ContactUs from "../ContactUs/ContactUs";
import AboutUs from "../../Components/AboutUs/AboutUs";
import NavCategories from "../../Components/NavCategories/NavCategories";

// img

import card1 from "../../Assets/bharti img/service/cookingimg.jpg";
import banner from "../../Assets/bharti img/homebanner.jpg";
import food from "../../Assets/bharti img/food.jpg";

const data = [
  {
    title: "What material do you use in making furniture?",
    description:
      "We deal in all sorts of engineered wood namely Ply, MDF, HDHMR and PLPB. Besides, we work in both sheet metal and MS pipes too. At certain places we use SS, Glass and Fabric too. Sofa structures are made both in Metal and Wood.",
  },
  {
    title: "How is factory made furniture better than carpentry work?",
    description:
      "Furniture at our factory is made with advance technology driven German & Japanese machinery which ensures precision and finesse with all the edges being subtle and smooth,  We have Hot Press Machine for pasting laminate which works with high pressure and temperature,  Hassel free process for the customer as factory made furniture involves no mess at the site, The ready to install material is delivered in knock down state and the only job done at site is of Installation of parts.",
  },
  {
    title: "Where in India do you supply your furniture?",
    description:
      "We have dual manufacturing facilities, 1 at Bangalore and other at Indore and we do pan India delivery.",
  },
  {
    title: "Do you customize designs as per client’s need?",
    description:
      "Yes, absolutely! Infact bespoke furniture is our USP and we can customize designs as per our client’s need.",
  },
  {
    title: "What is the warranty period offered on your products?",
    description:
      "A Warranty period of 1 year from the date of delivery on manufacturing defects is offered by us.",
  },
  {
    title: "What is the turnaround time of an order?",
    description:
      "The exact turnaround time depends on the designs, quantum of work and the availability of material in stock however, in general we take",
  },
];

function Home() {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);

  const closeBtn = () => {
    setShow(false);
  };

  const options = {
    publicKey: process.env.REACT_PUBLIC_KEY,
    // Do not allow headless browsers
    blockHeadless: true,
    limitRate: {
      // Set the limit rate for the application
      id: "best1_cleaners",
      // Allow 1 request per 10s
      throttle: 1000,
    },
  };

  const [formData, setFormData] = useState({
    email: "",
    message: "",
    phoneNumber: "",
    fullName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Replace these with your actual email service, template ID, and user ID
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    console.log("serviceId: ", serviceId);
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    console.log("templateId: ", templateId);

    try {
      await emailjs.send(serviceId, templateId, formData, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      });

      // Clear form data after successful submission
      setFormData({
        email: "",
        phoneNumber: "",
        message: "",
        fullName: "",
      });
      toast.success("Email send Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  useEffect(() => {
    emailjs.init(options);
  }, []);

  return (
    <>
      {/* <h5 className={styles.headingTop}>
        Welcome to Bharti Food & Hospitality And Corporate Catering, Redefined.
      </h5> */}
      <div className={styles.HomeMain}>
        <div className={styles.homeLeft}>
          <h1 className={styles.homeHeading}>
            WELCOME TO <br />
            <span style={{ color: "#EC8B23" }}>
              BHARTI FOODS & HOSPITALITY <br />{" "}
            </span>
          </h1>
          <h3 className="text-end">CORPORATE CATERING, REDEFINED</h3>
        </div>
        {/* <div className={styles.homeRight}>
          <img src={banner} alt="" />
        </div> */}
      </div>

      <div className={styles.wrapper} style={{ position: "relative" }}>
        {/* crousel */}
        {/* <HomeCrousel /> */}

        {/* LIST OF SERVICES */}
        {/* <div className={styles.servicesHead}> OUR SERVICES </div>
        <Services /> */}
      </div>
      {/* <div className="text-center">
        <img src={banner} alt="" className="text-center" />
      </div> */}

      <AboutUs />

      {/* SERVICES DESCRIPTION */}
      <div className={styles.aboutServices}>
        <div className={styles.servicesLeft}>
          <span className={styles.leftHead}> Our Corporate Packages</span>

          <span className={styles.leftDesc}>
            <ul>
              <li>Daily Meal Plans: Tasty, healthy meals delivered daily.</li>
              <li className="mt-2">
                Executive Lunches: Wow your clients with gourmet dishes.
              </li>
              <li className="mt-2">
                Event Catering: Leave a lasting impression with full-service
                catering for any corporate event.
              </li>
            </ul>
          </span>
        </div>

        <div className={styles.serviceImageContainer}>
          <img
            src={livingRoom}
            className={styles.aboutServicesImg}
            alt="services-image"
          />
        </div>
      </div>
      {/* 
      <div className="mt-5 cardproduct">
        <div className="row">
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card1} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={food} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card1} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={food} alt="" className="img-fluid" />
          </div>
          <div className="imgcard col-sm-4 col-md-3 col-lg-2">
            <img src={card1} alt="" className="img-fluid" />
          </div>
        </div>
      </div> */}

      <Testimonials />

      {/* faq section  */}
      {/* <div className={styles.faqContainer}>
        <FAQ data={data} />
      </div> */}

      <ContactUs />

      <div
        id="myModal"
        className="modal"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-content">
          <span className="close" onClick={closeBtn}>
            &times;
          </span>
          <form className={styles.contact_form} onSubmit={handleSubmit}>
            <span className={styles.formHeader}>SEND A MESSAGE</span>

            <div className={styles.input_group}>
              <div className={styles.formField}>
                <div className={styles.label}>Full Name</div>
                <input
                  className={styles.formInput}
                  type="text"
                  id="fullName"
                  onChange={handleInputChange}
                  name="fullName"
                  required
                />
              </div>

              <div className={styles.formField}>
                <div className={styles.label}>Email</div>
                <input
                  required
                  className={styles.formInput}
                  type="email"
                  id="email"
                  onChange={handleInputChange}
                  name="email"
                />
              </div>
            </div>
            <div className={styles.formField}>
              <div className={styles.label}>Phone</div>
              <input
                required
                className={styles.formInput}
                type="tel"
                id="phone"
                name="phoneNumber"
                onChange={handleInputChange}
              />
            </div>

            <div className={styles.formField}>
              <div className={styles.label}> Job Description </div>
              <textarea
                className={styles.formInput}
                id="jobDescription"
                name="message"
                rows="4"
                onChange={handleInputChange}
                required
              />
            </div>

            <button className={styles.submitBtn} type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>

      {/* <Footer /> */}
      <ToastContainer />
    </>
  );
}

export default Home;
