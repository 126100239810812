import React from "react";
import "./AboutUs.scss";
import { founderList, perksList } from "../../constants/constants";
import company from "../../Assets/Images/company.jpeg";

const AboutUs = () => {
  return (
    <section className="about-us-container mt-4">
      <div className="about-company-container">
        <div className="company-image-container">
          <div className="image-container">
            <img src={company} alt="company image" className="company-image" />
          </div>
        </div>

        <div className="company-details ">
          <div className="company-primary-details">
            <h4 className="subheading">Why Bharti Foods & Hospitality?</h4>

            {/* <ul>
              {perksList.map((item, index) => (
                <li>
                  <p>{item.perk}</p>
                </li>
              ))}
            </ul> */}
          </div>
          <ul>
            <li>
              <b>Customized Solutions:</b> Flexible packages tailored to fit
              your corporate needs.
            </li>
            <li className="mt-2">
              <b>Uncompromising Quality:</b> Fresh ingredients, gourmet flavors,
              impeccable service.
            </li>
            <li className="mt-2">
              <b>On-Time, Every Time:</b> We know how important punctuality is –
              expect us to deliver, always.{" "}
            </li>
            <li className="mt-2">
              <b>Professional Team:</b> Experienced chefs and staff to make
              every meal an event to remember.
            </li>
          </ul>
          <b>Let’s Elevate Your Corporate Dining Experience</b> <br />
          <p className="para mt-1">
            Good food fuels productivity and leaves a lasting impression.
            Whether it’s everyday meals or a special occasion, Bharti Food &
            Hospitality makes it unforgettable.
          </p>
        </div>
      </div>

      <div className="heading-section mt-5">
        <h3 className="heading">OUR SERVICES</h3>
      </div>
      <div className="founders-container">
        {founderList.map((item, index) => (
          <div key={index} className="founder-container">
            <div className="left-container">
              <div className="founder-image-container">
                <img
                  src={item.profileImage}
                  alt={`${item.name} image`}
                  className="founder-image"
                />
              </div>
            </div>

            <div className="right-container">
              <div className="primary-details">
                <h5 className="name">{item.name}</h5>
                {/* <p className="designation">{item.designation}</p> */}
              </div>

              <p className="about">{item.about}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default AboutUs;
