import { GiChickenOven } from "react-icons/gi";
import { GiBroom } from "react-icons/gi";
import { HiOfficeBuilding } from "react-icons/hi";
import { FaTools } from "react-icons/fa";
import { MdMapsHomeWork } from "react-icons/md";

// Background Images
import icon1 from "../../Assets/bharti img/service/catering (2).png";
import icon2 from "../../Assets/bharti img/service/event catering.png";
import icon3 from "../../Assets/bharti img/service/cafeteria.png";
import icon4 from "../../Assets/bharti img/service/menu.png";

import img1 from "../../Assets/bharti img/service/cookingimg.jpg";
import img2 from "../../Assets/bharti img/CorporateCatering.jpg";
import img3 from "../../Assets/bharti img/EventCatering.jpg";
import img4 from "../../Assets/bharti img/On-siteCafeteria.jpg";
import img5 from "../../Assets/bharti img/CustomMenus.webp";

import { MdOutlineAutoGraph } from "react-icons/md";

const servicesData = [
  {
    name: "Corporate Catering",
    imgURL: img2,
    logo: icon1,
    link: "/preset",
  },
  {
    name: "Event Catering",
    imgURL: img3,
    logo: icon2,
    link: "/jointventure",
  },
  {
    name: "On-site Cafeteria",
    imgURL: img4,
    logo: icon3,
    link: "/2xcapital",
  },
  {
    name: "Custom Menus",
    imgURL: img5,
    logo: icon4,
    link: "/Rentals",
  },
];

export default servicesData;
